import { tryGetLocalStorage } from '../app/utils/web-api';
import {WalletInfoWithOpenMethod} from "../models";

export class WalletInfoStorage {
    private readonly localStorage: Storage;

    private  storageKey = 'okx-wallet-connect-ui_wallet-info';

    constructor(storageKey:string) {
        this.localStorage = tryGetLocalStorage();
        this.storageKey = storageKey;
    }

    public setWalletInfo(walletInfo: WalletInfoWithOpenMethod): void {
        this.localStorage.setItem(this.storageKey, JSON.stringify(walletInfo));
    }

    public getWalletInfo(): WalletInfoWithOpenMethod | null {
        const walletInfoString = this.localStorage.getItem(this.storageKey);

        if (!walletInfoString) {
            return null;
        }

        return JSON.parse(walletInfoString);
    }

    public removeWalletInfo(): void {
        this.localStorage.removeItem(this.storageKey);
    }
}
