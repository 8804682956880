import {createEffect, createSignal} from 'solid-js';
import {Account, EngineTypes, logDebug, SessionTypes, WalletInfo} from "@okxconnect/core";
import {universalSingleWalletModalState} from "../../app/state/universal-modals-state";
import {universalWidgetController as widgetController} from "../../config/universal-widget-controller";
import {SingleWalletModalState} from "../../models/single-wallet-modal";
import {eqWalletName} from "../../app/utils/wallets";
import {OKXConnectUiError} from "../../errors";
import {isInTMA, sendExpand} from "../../app/utils/tma-api";
import {appState, setAppState} from "../../config/app.state";
import {ActionConfiguration, WalletsModalCloseReason} from "../../models";

interface UniversalSingleWalletModalManagerCreateOptions {
    getWalltsInfo: () => WalletInfo[]
}

class Signal<T = void> {
    private listeners: Array<(data: T | Error) => void> = [];
    public listen(callback: (data: T | Error) => void): void {
        this.listeners.push(callback);
    }
    public trigger(data: T | Error): void {
        this.listeners.forEach(listener => listener(data));
    }
}

/**
 * Manages the modal window state.
 */
export class UniversalSingleWalletModalManager {

    private consumers: Array<(state: SingleWalletModalState) => void> = [];
    private getWalltsInfo: () => WalletInfo[]
    private connectSignal = new Signal<SessionTypes.Struct | undefined>();

    public state: SingleWalletModalState = universalSingleWalletModalState();

    constructor(options: UniversalSingleWalletModalManagerCreateOptions) {
        this.getWalltsInfo = options.getWalltsInfo
        createEffect(() => {
            const state = universalSingleWalletModalState();
            this.state = state;
            this.consumers.forEach(consumer => consumer(state));
        });
    }

    /**
     * Opens the modal window with the specified wallet.
     * @param wallet - Wallet app name.
     * @param opts - Connect param
     * @throws OKXConnectUiError if the specified wallet is not found.
     */
    public async open(wallet: string,opts: EngineTypes.ConnectParams): Promise<SessionTypes.Struct | undefined>  {
        logDebug(`UniversalSingleWalletModalManager : open : ${JSON.stringify(opts)}`)
        const externalWallets = this.getWalltsInfo();
        const externalWallet = externalWallets.find(walletInfo => eqWalletName(walletInfo, wallet));
        if (externalWallet) {
            return this.openSingleWalletModal(externalWallet,opts);
        }

        const error = `Trying to open modal window with unknown wallet "${wallet}".`;
        throw new OKXConnectUiError(error);
    }

    /**
     * Closes the modal window.
     * @default 'action-cancelled'
     */
    public close(reason:WalletsModalCloseReason = 'action-cancelled'): void {
        widgetController.closeSingleWalletModal(reason);
    }
    /**
     * Subscribe to the modal window state changes, returns unsubscribe function.
     */
    public onStateChange(onChange: (state: SingleWalletModalState) => void): () => void {
        this.consumers.push(onChange);

        return () => {
            this.consumers = this.consumers.filter(consumer => consumer !== onChange);
        };
    }

    public async handleConnect(connectMethod: (actionConfiguration:ActionConfiguration | undefined) => Promise<SessionTypes.Struct | undefined>,
                               actionConfiguration:ActionConfiguration | undefined):Promise<SessionTypes.Struct | undefined> {
        return new Promise<SessionTypes.Struct | undefined>((resolve, reject) => {
            try {
                connectMethod(actionConfiguration).then((result) => {
                    resolve(result);
                    logDebug("UniversalSingleWalletModalManager  result >>>",JSON.stringify(result));
                    this.connectSignal.trigger(result);

                }).catch((error) => {
                    reject(error);
                    logDebug("UniversalSingleWalletModalManager  error >>>",JSON.stringify(error));
                    this.connectSignal.trigger(error);
                });
            } catch (error) {
                reject(error);
                logDebug("UniversalSingleWalletModalManager  catch error >>>",JSON.stringify(error));

                this.connectSignal.trigger(error);

            }
        });
    };
    /**
     * Opens the modal window to connect to a specified wallet, and waits when modal window is opened.
     */
    public async openSingleWalletModal(wallet: WalletInfo, opts:EngineTypes.ConnectParams): Promise<SessionTypes.Struct | undefined> {
        logDebug(`UniversalSingleWalletModalManager : openSingleWalletModal : ${JSON.stringify(opts)}`)
        if (isInTMA()) {
            sendExpand();
        }
        setAppState({universalConnectRequestParameters:opts})
        widgetController.openSingleWalletModal(wallet);
        return new Promise<SessionTypes.Struct | undefined >((resolve, reject) => {
            this.connectSignal.listen((resultOrError: SessionTypes.Struct | undefined | Error) => {
                if (resultOrError instanceof Error) {
                    reject(resultOrError);
                } else {
                    resolve(resultOrError);
                }
            });
        });
    }
}
