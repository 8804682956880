import { Component } from "solid-js";
import {
  ButtonsContainerStyled,
  ButtonsQRContainerStyled,
  ContainedPrimaryButtonText,
  H1StyledMaxWidth,
} from "./style";
import { OKXLoaderIconWithLogo } from "../../../../components/icons/okx-loader-icon";
import { QRIcon } from "../../../../components";
import { ModalRetryButton } from "./ConnectionDeclinedModalPage";
import { useTheme } from "solid-styled-components";

interface ContinueInWalletModalPageProps {
  onOpenQR: () => void;
  onRetry: () => void;
}

const ContinueInWalletModalPage: Component<ContinueInWalletModalPageProps> = (
  props
) => {
  const theme = useTheme();

  return (
    <>
      <ButtonsContainerStyled>
        <OKXLoaderIconWithLogo />
        <H1StyledMaxWidth translationKey="walletModal.mobileConnectionModal.continueIn">
          Continue the transaction in OKX Wallet
        </H1StyledMaxWidth>
      </ButtonsContainerStyled>
      <ButtonsContainerStyled>
        <ButtonsQRContainerStyled onClick={props.onOpenQR}>
          <QRIcon fill={theme.colors.background.primary} />

          <ContainedPrimaryButtonText translationKey="walletModal.mobileConnectionModal.showQR">
            Show QR Codes
          </ContainedPrimaryButtonText>
        </ButtonsQRContainerStyled>
        <ModalRetryButton onRetry={props.onRetry} />
      </ButtonsContainerStyled>
    </>
  );
};

export default ContinueInWalletModalPage;
