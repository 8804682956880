import { Component } from "solid-js";
import {
  ButtonsContainerStyled,
  ContainedPrimaryButton,
  ContainedPrimaryButtonText,
  TextStyled,
} from "./style";
import { OKXIconWithLogo } from "../../../../components/icons/okx-loader-icon";

interface ConnectAppTgPageProps {
  onConnectApp: () => void;
  onConnectMini: () => void;
}

const ConnectAppTgModalPage: Component<ConnectAppTgPageProps> = (props) => {
  return (
    <>
      <ButtonsContainerStyled>
        <OKXIconWithLogo />
        <TextStyled translationKey="walletModal.mobileConnectionModal.appAndTelegram.text">
          OKX Wallet supports OKX app and Telegram Mini Apps
        </TextStyled>
      </ButtonsContainerStyled>
      <ButtonsContainerStyled>
        <ContainedPrimaryButton onClick={props.onConnectApp}>
          <ContainedPrimaryButtonText translationKey="walletModal.mobileConnectionModal.connectApp">
            Connect to OKX app
          </ContainedPrimaryButtonText>
        </ContainedPrimaryButton>
        <ContainedPrimaryButton onClick={props.onConnectMini}>
          <ContainedPrimaryButtonText translationKey="walletModal.mobileConnectionModal.connectMini">
            Connect to Mini Apps
          </ContainedPrimaryButtonText>
        </ContainedPrimaryButton>
      </ButtonsContainerStyled>
    </>
  );
};

export default ConnectAppTgModalPage;
