import {Component, createSignal, useContext} from "solid-js";
import {ActionConfiguration, WalletsModalCloseReason} from "../../../models";
import {appState} from "../../../config/app.state";
import {UniversalConnectorContext} from "../../../config/connector.context";
import {OKXConnectUiContext} from "../../../config/okx-connect-ui.context";
import {SingleWalletModal} from "../../../app/views/modals/wallets-modal/single-wallet-modal";
import {EngineConnectInfo, EngineTypes, logDebug, SessionTypes} from "@okxconnect/core";
import {OKXUniversalConnectUI} from "../../okx-connect-universal-ui";
import {OKXConnectUiError} from "../../../errors";
import {
    defaultOpenUniversalLink,
    showUniversalTgWalletByRequestNameSpaces,
} from "../../../app/utils/wallets";
import { 
    getUniversalSingleWalletModalIsOpened,
    getUniversalSingleWalletModalWalletInfo,
    lastUniversalSelectedWalletInfo, 
    setLastUniversalSelectedWalletInfo,
} from "../../../app/state/universal-modals-state";

export const UniversalSingleWalletModal: Component = () => {
    const tonConnectUI = useContext(
        OKXConnectUiContext
    )! as OKXUniversalConnectUI;
    const connector = useContext(UniversalConnectorContext)!;
    const [universalLinkParams, setUniversalLinkParams] = createSignal<EngineConnectInfo | null>(null);

    const onClose = (closeReason: WalletsModalCloseReason): void => {
        tonConnectUI.closeModal(closeReason);
    };

    connector?.on("okx_engine_connect_params", (info: EngineConnectInfo) => {
        logDebug(`UniversalSigneWalletModal  okx_engine_connect_params  ${JSON.stringify(info)}`)
        setUniversalLinkParams(info)
    });

    return (
        <SingleWalletModal
            opened={getUniversalSingleWalletModalIsOpened()}
            walletInfo={getUniversalSingleWalletModalWalletInfo()}
            loadingBeforeConnect={() => {
                return false;
            }}
            connect={() => {
                return new Promise<SessionTypes.Struct>((resolve, reject) => {

                    if (appState.universalConnectRequestParameters) {
                        new OKXConnectUiError("connect params is nil");
                    }

                    let opts:EngineTypes.ConnectParams = {
                        ...JSON.parse(
                            JSON.stringify(appState.universalConnectRequestParameters)
                        ),
                    };
                    opts.sessionConfig = {}
                    opts.sessionConfig.openUniversalUrl =
                        defaultOpenUniversalLink(appState.universalConnectRequestParameters?.namespaces,
                            appState.universalConnectRequestParameters?.optionalNamespaces)

                    setUniversalLinkParams(null)
                    tonConnectUI.handleConnect((actionConfiguration:ActionConfiguration | undefined) => {
                        opts.sessionConfig!.tmaReturnUrl = actionConfiguration?.tmaReturnUrl
                        opts.sessionConfig!.redirect = actionConfiguration?.returnStrategy
                        return connector.connect(opts)
                    }).then((session) => {
                        if (session) {
                            onClose("wallet-selected");
                            resolve(session)
                        }else {
                            onClose("action-cancelled");
                        }
                    }).catch((error) => {
                        onClose("action-cancelled");
                        logDebug("UniversalSingleWalletModal connect  error", JSON.stringify(error))
                        setUniversalLinkParams(null)
                        reject(error);
                    });

                });
            }}
            onClose={onClose}
            universalLinkParams={universalLinkParams}
            isSingleWallet={!showUniversalTgWalletByRequestNameSpaces(appState.universalConnectRequestParameters?.namespaces,
                appState.universalConnectRequestParameters?.optionalNamespaces)}
            lastSelectedWalletInfo={lastUniversalSelectedWalletInfo()}
            setLastSelectedWalletInfo={setLastUniversalSelectedWalletInfo}
        />
    );
};
