import type { Property } from 'csstype';
import { Component } from 'solid-js';
import { Styleable } from '../../models/styleable';
import { useTheme } from 'solid-styled-components';

export interface ErrorIconProps extends Styleable {
    fill?: Property.Color;
    size?: 'xs' | 's' | 'm';
}

export const ErrorIcon: Component<ErrorIconProps> = props => {
    const theme = useTheme();

    const size = (): 'xs' | 's' | 'm' => props.size || 'm';
    const fill = (): string => props.fill || theme.colors.icon.error;

    return (
        <>
            {size() === 'm' ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="103" height="102" viewBox="0 0 103 102" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.5 14.8747C31.5487 14.8747 15.375 31.0485 15.375 50.9997C15.375 70.951 31.5487 87.1247 51.5 87.1247C71.4513 87.1247 87.625 70.951 87.625 50.9997C87.625 31.0485 71.4513 14.8747 51.5 14.8747ZM9 50.9997C9 27.5276 28.0279 8.49974 51.5 8.49974C74.9721 8.49974 94 27.5276 94 50.9997C94 74.4718 74.9721 93.4997 51.5 93.4997C28.0279 93.4997 9 74.4718 9 50.9997ZM65.0234 37.4765C66.2682 38.7213 66.2682 40.7395 65.0234 41.9843L56.0078 50.9999L65.0234 60.0155C66.2682 61.2603 66.2682 63.2785 65.0234 64.5233C63.7786 65.7681 61.7604 65.7681 60.5156 64.5233L51.5 55.5077L42.4844 64.5233C41.2396 65.7681 39.2214 65.7681 37.9766 64.5233C36.7318 63.2785 36.7318 61.2603 37.9766 60.0155L46.9922 50.9999L37.9766 41.9843C36.7318 40.7395 36.7318 38.7213 37.9766 37.4765C39.2214 36.2317 41.2396 36.2317 42.4844 37.4765L51.5 46.4921L60.5156 37.4765C61.7604 36.2317 63.7787 36.2317 65.0234 37.4765Z" fill="#909090"/>
                </svg>
            ) : size() === 's' ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 103 102" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.5 14.8747C31.5487 14.8747 15.375 31.0485 15.375 50.9997C15.375 70.951 31.5487 87.1247 51.5 87.1247C71.4513 87.1247 87.625 70.951 87.625 50.9997C87.625 31.0485 71.4513 14.8747 51.5 14.8747ZM9 50.9997C9 27.5276 28.0279 8.49974 51.5 8.49974C74.9721 8.49974 94 27.5276 94 50.9997C94 74.4718 74.9721 93.4997 51.5 93.4997C28.0279 93.4997 9 74.4718 9 50.9997ZM65.0234 37.4765C66.2682 38.7213 66.2682 40.7395 65.0234 41.9843L56.0078 50.9999L65.0234 60.0155C66.2682 61.2603 66.2682 63.2785 65.0234 64.5233C63.7786 65.7681 61.7604 65.7681 60.5156 64.5233L51.5 55.5077L42.4844 64.5233C41.2396 65.7681 39.2214 65.7681 37.9766 64.5233C36.7318 63.2785 36.7318 61.2603 37.9766 60.0155L46.9922 50.9999L37.9766 41.9843C36.7318 40.7395 36.7318 38.7213 37.9766 37.4765C39.2214 36.2317 41.2396 36.2317 42.4844 37.4765L51.5 46.4921L60.5156 37.4765C61.7604 36.2317 63.7787 36.2317 65.0234 37.4765Z" fill="#909090"/>
                </svg>
            ) : (
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 103 102" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M51.5 14.8747C31.5487 14.8747 15.375 31.0485 15.375 50.9997C15.375 70.951 31.5487 87.1247 51.5 87.1247C71.4513 87.1247 87.625 70.951 87.625 50.9997C87.625 31.0485 71.4513 14.8747 51.5 14.8747ZM9 50.9997C9 27.5276 28.0279 8.49974 51.5 8.49974C74.9721 8.49974 94 27.5276 94 50.9997C94 74.4718 74.9721 93.4997 51.5 93.4997C28.0279 93.4997 9 74.4718 9 50.9997ZM65.0234 37.4765C66.2682 38.7213 66.2682 40.7395 65.0234 41.9843L56.0078 50.9999L65.0234 60.0155C66.2682 61.2603 66.2682 63.2785 65.0234 64.5233C63.7786 65.7681 61.7604 65.7681 60.5156 64.5233L51.5 55.5077L42.4844 64.5233C41.2396 65.7681 39.2214 65.7681 37.9766 64.5233C36.7318 63.2785 36.7318 61.2603 37.9766 60.0155L46.9922 50.9999L37.9766 41.9843C36.7318 40.7395 36.7318 38.7213 37.9766 37.4765C39.2214 36.2317 41.2396 36.2317 42.4844 37.4765L51.5 46.4921L60.5156 37.4765C61.7604 36.2317 63.7787 36.2317 65.0234 37.4765Z" fill="#909090"/>
                </svg>
            )}
        </>
    );
};
