const debug = false
export function logDebug(...args: Parameters<Console['debug']>): void {
    if (typeof 'console' !== undefined) {
        try {
            if (debug || (window as any)?.okxLogDebug){
                console.debug('[OKX_TON_CONNECT_UI]', ...args);
            }
        } catch {}
    }
}

export function logError(...args: Parameters<Console['error']>): void {
    if (typeof 'console' !== undefined) {
        try {
            if (debug || (window as any)?.okxLogDebug){
                console.error('[OKX_TON_CONNECT_UI]', ...args);
            }
        } catch {}
    }
}

export function logWarning(...args: Parameters<Console['warn']>): void {
    if (typeof 'console' !== undefined) {
        try {
            if (debug || (window as any)?.okxLogDebug){
                console.warn('[OKX_TON_CONNECT_UI]', ...args);
            }
        } catch {}
    }
}
