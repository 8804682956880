import type {Component} from 'solid-js';
import {Dynamic} from 'solid-js/web';
import {ThemeProvider} from 'solid-styled-components';
import {createI18nContext, I18nContext} from '@solid-primitives/i18n';
import {i18nDictionary} from "../app/i18n";
import {defineStylesRoot, fixMobileSafariActiveTransition} from "../app/utils/web-api";
import {
  GlobalStyles,
  globalStylesTag,
} from "../app/styles/global-styles";
import {themeState} from "../app/state/theme-state";
import {ActionsModal} from "../app/views/modals";
import {appState, setAppState} from "../config/app.state";
import {UniversalConnectorContext} from "../config/connector.context";
import {OKXConnectUiContext} from "../config/okx-connect-ui.context";
import {OKXUniversalConnectUI} from "./okx-connect-universal-ui";
import {UniversalSingleWalletModal} from "./modals/wallets-modal/single-universal-wallet-modal";
import { universalAction, setUniversalAction } from '../app/state/universal-modals-state';

export type UniversalAppProps = {
    connectUI: OKXUniversalConnectUI;
};

export const UniversalApp: Component<UniversalAppProps> = props => {
    const translations = createI18nContext(i18nDictionary, appState.language);

    defineStylesRoot();
    fixMobileSafariActiveTransition();
    setAppState({contextType:'UniversalContext'})

    // console.log(`UniversalApp UniversalApp UniversalApp  ${JSON.stringify(themeState.colors)}`)

    return (
      <I18nContext.Provider value={translations}>
        <OKXConnectUiContext.Provider value={props.connectUI}>
          <UniversalConnectorContext.Provider
            value={appState.universalConnector!}
          >
            <GlobalStyles />
            <ThemeProvider theme={themeState}>
              <Dynamic component={globalStylesTag}>
                <UniversalSingleWalletModal />
                <ActionsModal action={universalAction()} setAction={setUniversalAction} />
              </Dynamic>
            </ThemeProvider>
          </UniversalConnectorContext.Provider>
        </OKXConnectUiContext.Provider>
      </I18nContext.Provider>
    );
};