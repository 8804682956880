import { Component } from "solid-js";
import { keyframes, styled, useTheme } from "solid-styled-components";

import { Image } from "../../components";
import { LoaderIcon, LoaderIconProps } from "./loader-icon";
import imageUrl from "../../assets/ok_icon.png";
import { Styleable } from "../../models/styleable";

export const OKXLoaderIconWithLogo: Component<Styleable> = (_) => {
  return (
    <LoaderContainerStyled>
      <LoaderStyled />
      <OKIconStyle src={imageUrl} />
    </LoaderContainerStyled>
  );
};

// should this be in another file?
export const OKXIconWithLogo: Component<Styleable> = (_) => {
  return (
    <LoaderContainerStyled>
      <OKIconStyle src={imageUrl} />
    </LoaderContainerStyled>
  );
};

const OKXLoaderIcon: Component<LoaderIconProps> = (_) => {
  const theme = useTheme();

  const rotateAnimation = keyframes`
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    `;
  const RotatingSVG = styled.svg`
    animation: ${rotateAnimation} 2s linear infinite;
    transform-origin: center;
  `;

  return (
    <RotatingSVG
      xmlns="http://www.w3.org/2000/svg"
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M96 51C96 75.8528 75.8528 96 51 96C26.1472 96 6 75.8528 6 51C6 26.1472 26.1472 6 51 6C75.8528 6 96 26.1472 96 51ZM102 51C102 79.1665 79.1665 102 51 102C22.8335 102 0 79.1665 0 51C0 22.8335 22.8335 0 51 0C79.1665 0 102 22.8335 102 51Z"
        fill={theme.colors.text.primary}
        fill-opacity="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6 51H0C0 79.1665 22.8335 102 51 102V96C26.1472 96 6 75.8528 6 51Z"
        fill={theme.colors.text.primary}
      />
    </RotatingSVG>
  );
};

export const LoaderContainerStyled = styled.div`
  position: relative;
  height: 102px;
  width: 102px;
`;

export const LoaderStyled = styled(OKXLoaderIcon)`
  height: 102px;
  width: 102px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const OKIconStyle = styled(Image)`
  position: absolute;
  width: 86px;
  height: 86px;
  border-radius: 44px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
