import { createStore } from 'solid-js/store';
import { Locales } from '../models';
import { ReturnStrategy } from '../models';
import { Loadable } from '../models';

import {OKXITonConnect, ConnectAdditionalRequest, EngineTypes} from '@okxconnect/tonsdk';
import { OKXUniversalProvider } from '@okxconnect/universal-provider';

export type ConnectUIType = 'TONContext' | 'UniversalContext'

export type AppState = {
    tonConnector: OKXITonConnect | null;
    universalConnector: OKXUniversalProvider | null;
    buttonRootId: string | null;
    language: Locales;
    connectRequestParameters?: Loadable<ConnectAdditionalRequest> | null;
    universalConnectRequestParameters?:EngineTypes.ConnectParams;
    returnStrategy: ReturnStrategy;
    tmaReturnUrl:ReturnStrategy;
    preferredWalletAppName: string | undefined;
    contextType:ConnectUIType;
};

export const [appState, setAppState] = createStore<AppState>({
    buttonRootId: null,
    language: 'en_US',
    returnStrategy: 'none',
} as AppState);
