import {
  encodeConnectParams,
  encodeTWAConnectURLParams,
  EngineConnectInfo,
  getAppWalletDeepLink,
  getTelegramWalletTWAUrl,
  getUniversalLink,
  logDebug,
  openOKXTMAWalletlinkWithFallback,
  WalletInfo
} from "@okxconnect/core";
import {Component, createEffect, createMemo, createSignal, JSX, onCleanup, onMount, Show, useContext,} from "solid-js";
import {BodyStyled, MobileConnectionModalStyled} from "./style";
import {useTheme} from "solid-styled-components";
import {MobileConnectionQR} from "../mobile-connection-modal/mobile-connection-qr";
import {ConnectorContext} from "../../../../../config/connector.context";
import ConnectAppTgModalPage from "../layout/ConnectAppTgModalPage";
import LoadingModalPage from "../layout/LoadingModalPage";
import {H1Styled} from "../style";
import ConnectionDeclinedModalPage from "../layout/ConnectionDeclinedModalPage";
import ModalFooter from "../layout/ModalFooter";
import {isInTelegramBrowser, isInTMA} from "../../../../utils/tma-api";
import {browserDebug} from "../../../../utils/wallets";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import { WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod } from "../../../../../models";

export interface DesktopConnectionProps {
  wallet: WalletInfo;
  onBackClick: () => void;
  backDisabled?: boolean;
  connect: () => Promise<unknown>;
  isSingleWallet: boolean;
  universalLinkParams: () => EngineConnectInfo | null;
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
}

export const DesktopConnectionModal: Component<DesktopConnectionProps> = (
  props
) => {
  const theme = useTheme();
  const [firstClick, setFirstClick] = createSignal(true);
  const [showQR, setShowQR] = createSignal(false);
  const [walletType, setWalletType] = createSignal<"app" | "mini" | null>(null);
  const showAppAndTgState = createMemo(
    () => !props.isSingleWallet && walletType() === null
  );
  const [connectionErrored, setConnectionErrored] = createSignal(false);
  const connector = useContext(ConnectorContext)!;
  const [miniUniversalLink, setMiniUniversalLink] = createSignal<string | null>(
    null
  );
  const okxConnectUI = useContext(OKXConnectUiContext)

  createEffect(() => {
    logDebug("TON customEvent.detail  :::",createEffect)
    var info = props.universalLinkParams();
    logDebug("TON customEvent.detail  :::",JSON.stringify(info))
    if (info) {
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "universal-link",
      });

      var connectRequest = info.connectRequest
      var tonConnectRequest = info.tonConnectRequest

      if (!tonConnectRequest){
        tonConnectRequest = connectRequest
      }

      if (connectRequest) {
        var encodeParams = encodeConnectParams({
          ...tonConnectRequest,
          redirect: "none"
        })

        var qrLink = getUniversalLink(getAppWalletDeepLink(encodeParams))
        setQrLink(qrLink);

        var encodeTGParams = encodeTWAConnectURLParams(connectRequest)
        var tgUrl = getTelegramWalletTWAUrl(encodeTGParams, info.tmaReturnUrl ?? "back", browserDebug())
        setMiniUniversalLink(tgUrl)

        logDebug(`DesktopConnectionModal  setUniversalLink  tgUrl  ${tgUrl}    qrLink ${qrLink}`)
      }
    }
  });

  const [qrLink, setQrLink] = createSignal<string | null>(null);

  const unsubscribe = connector?.onStatusChange(
    () => {},
    () => {
      setConnectionErrored(true);
      const info = props.lastSelectedWalletInfo;
      if (info && info.openMethod && info.openMethod === "qrcode") {
        if (showQR()) {
          onCloseQR();
        }
      }
    }
  );

  onMount(async () => {
    try {
       await props.connect().catch((error) => 
        logDebug("DesktopConnectionModal connect catch error ",JSON.stringify(error))
      );
    } catch (error) {
      logDebug("DesktopConnectionModal connect  error ",JSON.stringify(error));
    }
  });

  const onRetry = (): void => {
    setConnectionErrored(false);
    setWalletType(null);
    const forceRedirect = !firstClick();
    setFirstClick(false);
    try {
      props.connect();
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  };

  const onOpenQR = (): void => {
    setConnectionErrored(false);
    setShowQR(true);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "qrcode",
    });
  };

  const onCloseQR = (): void => {
    setShowQR(false);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "universal-link",
    });
  };

  const onConnectApp = (): void => {
    setQrLink(qrLink()!);
    setWalletType("app");
    onOpenQR();
  };

  const onConnectMini = (): void => {
    // setWalletType("mini");
    logDebug('desktop on connect mini')
    okxConnectUI?.closeModal()
    openOKXTMAWalletlinkWithFallback(miniUniversalLink()!, browserDebug())
  };

  if (unsubscribe) {
    onCleanup(unsubscribe);
  }

  return (
    <MobileConnectionModalStyled data-tc-wallets-modal-connection-mobile="true">
      <Show<boolean, () => JSX.Element>
        when={
          props.universalLinkParams() === null ||
          connectionErrored() ||
          showAppAndTgState()
        }
      >
        <H1Styled>OKX Connect</H1Styled>
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() === null}>
        <LoadingModalPage />
        <ModalFooter wallet={props.wallet} />
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() !== null}>
        <Show<boolean, () => JSX.Element> when={connectionErrored()}>
          <BodyStyled>
            <ConnectionDeclinedModalPage onRetry={onRetry} />
          </BodyStyled>
        </Show>

        <Show<boolean, () => JSX.Element> when={!connectionErrored()}>
          <Show<boolean, () => JSX.Element> when={!showAppAndTgState()}>
            <MobileConnectionQR
              universalLink={qrLink()}
              walletInfo={props.wallet}
            />
          </Show>

          <Show<boolean, () => JSX.Element> when={showAppAndTgState()}>
            <BodyStyled>
              <ConnectAppTgModalPage
                onConnectApp={onConnectApp}
                onConnectMini={onConnectMini}
              />
            </BodyStyled>
          </Show>
        </Show>

        <ModalFooter wallet={props.wallet} />
      </Show>
    </MobileConnectionModalStyled>
  );
};
