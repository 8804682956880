import { Component } from "solid-js";
import {
  ButtonsContainerStyled,
  ErrorIconStyled,
  H1StyledMaxWidth,
  H1StyledRetry,
  RetryButtonStyle,
} from "./style";
import { RetryIcon } from "../../../../components";

interface ModalRetryButtonProps {
  onRetry: () => void;
}

export const ModalRetryButton: Component<ModalRetryButtonProps> = (props) => {
  return (
    <RetryButtonStyle leftIcon={<RetryIcon />} onClick={props.onRetry}>
      <H1StyledRetry translationKey="common.retry">Retry</H1StyledRetry>
    </RetryButtonStyle>
  );
};

interface ConnectionDeclinedModalPageProps {
  onRetry: () => void;
}

const ConnectionDeclinedModalPage: Component<
  ConnectionDeclinedModalPageProps
> = (props) => {
  return (
    <>
      <ErrorIconStyled size="s" />
      <H1StyledMaxWidth translationKey="walletModal.mobileConnectionModal.connectionDeclined">
        Connection declined
      </H1StyledMaxWidth>
      <ButtonsContainerStyled>
        <ModalRetryButton onRetry={props.onRetry} />
      </ButtonsContainerStyled>
    </>
  );
};

export default ConnectionDeclinedModalPage;
