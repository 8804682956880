import { createMemo, createSignal } from 'solid-js';
import { WalletInfoWithOpenMethod, WalletOpenMethod } from '../../models';
import { LastSelectedWalletInfoStorage } from '../../storage';
import { WalletsModalState } from '../../models';
import { SingleWalletModalState } from '../../models/single-wallet-modal';
import {logDebug} from "../utils/log";
import { Action } from '../models/action-modal';

export const [universalWalletsModalState, setUniversalWalletsModalState] = createSignal<WalletsModalState>({
    status: 'closed',
    closeReason: null
});

export const getUniversalWalletsModalIsOpened = createMemo(
    () => universalWalletsModalState().status === 'opened'
);

export const [universalSingleWalletModalState, setUniversalSingleWalletModalState] =
    createSignal<SingleWalletModalState>({
        status: 'closed',
        closeReason: null
    });

export const getUniversalSingleWalletModalIsOpened = createMemo(
    () => universalSingleWalletModalState().status === 'opened'
);

export const getUniversalSingleWalletModalWalletInfo = createMemo(() => {
    const state = universalSingleWalletModalState();
    if (state.status === 'opened') {
        return state.walletInfo;
    }

    return null;
});

let lastSelectedWalletInfoStorage =
    typeof window !== 'undefined' ? new LastSelectedWalletInfoStorage() : undefined;
export const [lastUniversalSelectedWalletInfo, _setLastUniversalSelectedWalletInfo] = createSignal<
    | WalletInfoWithOpenMethod
    | {
          openMethod: WalletOpenMethod;
      }
    | null
>(lastSelectedWalletInfoStorage?.getLastSelectedWalletInfo() || null);

export const setLastUniversalSelectedWalletInfo = (
    walletInfo:
        | WalletInfoWithOpenMethod
        | {
              openMethod: WalletOpenMethod;
          }
        | null
): void => {
    if (!lastSelectedWalletInfoStorage) {
        lastSelectedWalletInfoStorage = new LastSelectedWalletInfoStorage();
    }

    if (walletInfo) {
        logDebug(`modals-state.ts: setLastSelectedWalletInfo ${JSON.stringify(walletInfo)}`)
        lastSelectedWalletInfoStorage.setLastSelectedWalletInfo(walletInfo);
    } else {
        logDebug("modals-state.ts: setLastSelectedWalletInfo    removeLastSelectedWalletInfo")
        lastSelectedWalletInfoStorage.removeLastSelectedWalletInfo();
    }
    _setLastUniversalSelectedWalletInfo(walletInfo);
};

export const [universalAction, setUniversalAction] = createSignal<Action | null>(null);
