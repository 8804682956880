import { Component, useContext } from 'solid-js';
import { ActionModal } from '../action-modal';
import { LoaderIcon } from '../../../../components';
import { useI18n } from '@solid-primitives/i18n';
import {OKXLoaderIconWithLogo} from '../../../../components/icons/okx-loader-icon';
import {isMobile} from '../../../../hooks/isMobile';
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";

interface ConfirmTransactionModalProps {
    onClose: () => void;
}

export const ConfirmTransactionModal: Component<ConfirmTransactionModalProps> = props => {
    const tonConnectUI = useContext(OKXConnectUiContext);
    const [t] = useI18n();
    const name = (): string =>
        tonConnectUI!.walletName
            ? tonConnectUI!.walletName
            : t('common.yourWallet', {}, 'Your wallet');

    return (
        <ActionModal
            headerTranslationKey="actionModal.confirmTransaction.header"
            headerTranslationValues={{ name: name() }}
            textTranslationKey="actionModal.confirmTransaction.text"
            icon={<OKXLoaderIconWithLogo/> }
            onClose={() => props.onClose()}
            showButton={isMobile() ? 'open-wallet' : undefined}
            data-tc-confirm-modal="true"
        />
    );
};
