import { render } from 'solid-js/web';

import {
    lastUniversalSelectedWalletInfo,
    setUniversalAction,
    setLastUniversalSelectedWalletInfo,
    setUniversalSingleWalletModalState,
} from '../app/state/universal-modals-state';
import {WalletInfoWithOpenMethod, WalletOpenMethod, WalletsModalCloseReason} from '../models';
import {logDebug, WalletInfo} from "@okxconnect/core";
import {OKXTonConnectUI} from "../ton";
import {OKXUniversalConnectUI} from "../universal/okx-connect-universal-ui";
import {TONApp} from "../ton/TONApp";
import {UniversalApp} from "../universal/UniversalApp";
import { Action } from '../app/models/action-modal';

export const universalWidgetController = {
    openSingleWalletModal: (walletInfo: WalletInfo): void => {
        logDebug(`universalWidgetController : openSingleWalletModal : ${JSON.stringify(walletInfo)}`)
        void setTimeout(() =>
            setUniversalSingleWalletModalState({
                status: 'opened',
                closeReason: null,
                walletInfo: walletInfo
            })
        );
    },
    closeSingleWalletModal: (reason: WalletsModalCloseReason): void =>
        void setTimeout(() =>
            setUniversalSingleWalletModalState({
                status: 'closed',
                closeReason: reason
            })
        ),
    setAction: (action: Action): void => void setTimeout(() => setUniversalAction(action)),
    clearAction: (): void => void setTimeout(() => {console.log('clearAction'); setUniversalAction(null)}),
    getSelectedWalletInfo: ():
        | WalletInfoWithOpenMethod
        | {
              openMethod: WalletOpenMethod;
          }
        | null => lastUniversalSelectedWalletInfo(),
    removeSelectedWalletInfo: (): void => setLastUniversalSelectedWalletInfo(null),
    renderApp: (root: string, connectUI:OKXTonConnectUI ): (() => void) => {
       return render(
            () => <TONApp connectUI={connectUI} />,
            document.getElementById(root) as HTMLElement
        )
    },
    renderUniversalApp: (root: string, connectUI: OKXUniversalConnectUI): (() => void) => {
       return render(
            () => <UniversalApp connectUI={connectUI} />,
            document.getElementById(root) as HTMLElement
        )
    }
};
