import {
    creatOKXWalletInfo,
    EngineTypes, logDebug, OKX_CONNECT_ERROR_CODES,
    OKXConnectError as CoreOKXConnectError,
    RequestArguments,
    SessionTypes,
    WalletInfo
} from "@okxconnect/core";
import {universalWidgetController as widgetController} from '../config/universal-widget-controller';
import {getSystemTheme, subscribeToThemeChange} from '../app/utils/web-api';
import {setTheme} from '../app/state/theme-state';
import {mergeOptions} from '../app/utils/options';
import {setAppState} from '../config/app.state';
import {unwrap} from 'solid-js/store';
import {
    ActionConfiguration,
    StrictActionConfiguration,
} from '../models/action-configuration';
import {OKXConnectUiError} from '../errors';
import {OKXConnectUiOptions, WalletsModalCloseReason,} from '../models';
import {
    OKXConnectError as UniversalProviderOKXConnectError,
    OKXUniversalProvider
} from "@okxconnect/universal-provider";
import {OKXConnectUI} from "../okx-connect-ui.interface";
import {UniversalSingleWalletModalManager} from "./modals/UniversalSingleWalletModalManager";
import {OKXUniversalConnectUiCreateOptions} from "./okx-connect-universal-ui-create-options";
import {
    defaultOpenTonUniversalLink,
    eqWalletName,
    isAppWallet,
    openUniversalWallet,
    openWallet,
    openWalletForUIRequest
} from "../app/utils/wallets";
import {isMobile} from "../app/hooks/isMobile";
import {creatOKXMiniAppWalletInfo} from "@okxconnect/core";
import { isDevice } from "../app/styles/media";
import {isValidLocale} from "../models/locales";
import { lastUniversalSelectedWalletInfo } from "../app/state/universal-modals-state";

export class OKXUniversalConnectUI extends OKXUniversalProvider implements OKXConnectUI {
    public static getWallets(): WalletInfo[] {
        return [creatOKXWalletInfo(),creatOKXMiniAppWalletInfo()];
    }

    private readonly singleWalletModal: UniversalSingleWalletModalManager;
    private actionsConfiguration?: ActionConfiguration;
    private systemThemeChangeUnsubscribe: (() => void) | null = null;
    private dispose:()=>void

    private getConnectWallet(): WalletInfo | undefined {
        logDebug(`this.session?.wallet?.appName ${this.session?.wallet?.appName}`)
        return this.getWallets().find(wallet => eqWalletName(wallet,this.session?.wallet?.appName))
    }

    public get universalLink(): string | undefined {
        logDebug(`this.getConnectWallet()?.universalLink ${this.getConnectWallet()?.universalLink}`)
        return this.getConnectWallet()?.universalLink;
    }

    public get deepLink():string | undefined{
        return this.getConnectWallet()?.deepLink;
    }

    public get walletName(): string | undefined {
        logDebug(`this.getConnectWallet()?.walletName ${this.getConnectWallet()?.name}`)
        return this.getConnectWallet()?.name;
    }

    public set uiOptions(options: OKXConnectUiOptions) {
        logDebug('OKXUniversalConnectUI uiOptions() called')
        this.actionsConfiguration = options.actionsConfiguration;

        if (options.uiPreferences?.theme) {
            if (options.uiPreferences?.theme !== 'SYSTEM') {
                this.systemThemeChangeUnsubscribe?.();
                // setTheme(options.uiPreferences.theme, options.uiPreferences.colorsSet);
                setTheme(options.uiPreferences.theme);
            } else {
                // setTheme(getSystemTheme(), options.uiPreferences.colorsSet);
                setTheme(getSystemTheme());

                if (!this.systemThemeChangeUnsubscribe) {
                    this.systemThemeChangeUnsubscribe = subscribeToThemeChange(setTheme);
                }
            }
        } else {
            // if (options.uiPreferences?.colorsSet) {
            //     setColors(options.uiPreferences.colorsSet);
            // }
        }

        if (options.language && !isValidLocale(options.language.toString())){
            options.language = "en_US"
        }

        setAppState(state => {
            const merged = mergeOptions(
                {
                    ...(options.language && { language: options.language }),
                    ...(!!options.actionsConfiguration?.returnStrategy && {
                        returnStrategy: options.actionsConfiguration.returnStrategy
                    }),
                },
                unwrap(state)
            );
            return merged;
        });
    }

    public destory(){
        if (this.dispose){
            this.dispose()
        }
    }

    public async request<T = unknown>(
        args: Omit<RequestArguments, 'redirect'>,
        chain?: string | undefined,
        actionConfiguration?: ActionConfiguration,
    ): Promise<T> {
        logDebug('OKXUniversalConnectUI request() called')
        if (!this.session) {
            throw new OKXConnectUiError('Connect wallet to send a transaction.');
        }
        const options: ActionConfiguration = {
          returnStrategy: (
            isDevice("mobile") &&
            lastUniversalSelectedWalletInfo()?.openMethod !== 'qrcode'
        ) ? actionConfiguration?.returnStrategy : "none", //redirect
          modals: actionConfiguration?.modals,
          tmaReturnUrl: actionConfiguration?.tmaReturnUrl ?? "back",
        };
        const { modals, returnStrategy, tmaReturnUrl } =
          this.getModalsAndNotificationsConfiguration(options);
        const reqArgs: RequestArguments = { ...args, redirect: returnStrategy };
        const showRequestModal = super.showRequestModal(reqArgs, chain);
        widgetController.setAction({
            name: 'confirm-transaction',
            openModal: modals.includes('before') && showRequestModal,
            sent: false
        });
        if (
            !this.session.sessionConfig?.openUniversalUrl &&
            showRequestModal && 
            openWalletForUIRequest(this.getConnectWallet(), lastUniversalSelectedWalletInfo()?.openMethod)
        ){
          openUniversalWallet(
            this.getConnectWallet(),
            tmaReturnUrl,
            this.session.namespaces
          );
        }

        if (this.session.sessionConfig) {
            this.session.sessionConfig.openUniversalUrl = this.session.sessionConfig.openUniversalUrl && isMobile() && lastUniversalSelectedWalletInfo()?.openMethod !== 'qrcode';
        }
        try {
            const result = await super.request<T>(reqArgs,chain);
            widgetController.setAction({
                name: 'transaction-sent',
                openModal: modals.includes('success') && showRequestModal
            });
            return result;
        } catch (e) {
            logDebug(`OKXUniversalConnectUI  request  ==>  ${typeof e} ==> is core OKXConnectError: ${e instanceof CoreOKXConnectError} ==> is universal-provider OKXConnectError: ${e instanceof UniversalProviderOKXConnectError} ==>  ${JSON.stringify(e)}`)
            widgetController.setAction({
                name: 'transaction-canceled',
                openModal: modals.includes('error') && showRequestModal
            });

            if (e instanceof CoreOKXConnectError || e instanceof UniversalProviderOKXConnectError) {
                logDebug(`OKXUniversalConnectUI  request  ==> ${JSON.stringify(e)}`)
                throw e;
            } else {
                throw new OKXConnectUiError('Unhandled error:' + e);
            }
        }
    }

    private getModalsAndNotificationsConfiguration(
        options?: ActionConfiguration
    ): StrictActionConfiguration {
        const allActions: StrictActionConfiguration['modals'] = [
            'before',
            'success',
            'error'
        ];

        let modals: StrictActionConfiguration['modals'] = ['before'];
        if (this.actionsConfiguration?.modals) {
            if (this.actionsConfiguration.modals === 'all') {
                modals = allActions;
            } else {
                modals = this.actionsConfiguration.modals;
            }
        }
        if (options?.modals) {
            if (options.modals === 'all') {
                modals = allActions;
            } else {
                modals = options.modals;
            }
        }

        const returnStrategy = options?.returnStrategy || this.actionsConfiguration?.returnStrategy || 'back';
        const tmaReturnUrl =
            options?.tmaReturnUrl || this.actionsConfiguration?.tmaReturnUrl || 'back';

        return {
            modals,
            tmaReturnUrl,
            returnStrategy,
        };
    }

    public static async init(opts: OKXUniversalConnectUiCreateOptions) {
        logDebug('OKXUniversalConnectUI init() called')
        if (opts && 'dappMetaData' in opts && opts.dappMetaData) {
            let provider: OKXUniversalConnectUI;
            if (window && (window as any).okxConnectUniversalUIinwindow && (window as any).okxConnectUniversalUIinwindow instanceof OKXUniversalConnectUI){
                logDebug('OKXUniversalConnectUI init() old called')
                provider = (window as any).okxConnectUniversalUIinwindow
            }else{
                logDebug('OKXUniversalConnectUI init() new called')
                provider = new OKXUniversalConnectUI(opts);
                await provider.initialize()
                if (window){
                    (window as any).okxConnectUniversalUIinwindow = provider
                }
            }
            return provider;
        } else {
            throw new OKXConnectUiError(
                'You have to specify a `dappMetaData`in the options.'
            );
        }
    }

    private constructor(options: OKXUniversalConnectUiCreateOptions) {
        super({dappMetaData:options.dappMetaData})
        logDebug("OKXUniversalConnectUI constructor options:",JSON.stringify(options))
        this.singleWalletModal = new UniversalSingleWalletModalManager({
            getWalltsInfo: () => OKXUniversalConnectUI.getWallets()
        });
        const rootId = this.normalizeWidgetRoot(undefined);

        this.uiOptions = mergeOptions(options, {uiPreferences: {theme: 'SYSTEM'}, dappMetaData: options.dappMetaData});
        setAppState({
            universalConnector: this,
            preferredWalletAppName: undefined
        });
        this.dispose = widgetController.renderUniversalApp(rootId, this);
    }



    private normalizeWidgetRoot(rootId: string | undefined): string {
        if (!rootId || !document.getElementById(rootId)) {
            rootId = `universal-widget-root`;
            const rootElement = document.createElement('div');
            rootElement.id = rootId;
            document.body.appendChild(rootElement);
        }

        return rootId;
    }


    public getWallets(): WalletInfo[] {
        return OKXUniversalConnectUI.getWallets();
    }

    public async handleConnect(connectMethod: (actionConfiguration:ActionConfiguration | undefined) => Promise<SessionTypes.Struct | undefined>):Promise<SessionTypes.Struct | undefined> {
      return  this.singleWalletModal.handleConnect(connectMethod,this.actionsConfiguration);
    };


    public async openModal(opts: EngineTypes.ConnectParams): Promise<SessionTypes.Struct | undefined> {
        logDebug('OKXUniversalConnectUI openModal() called')
        try {
            if (this.connected()){
                return this.session
            }
            return this.singleWalletModal.open("okxAppWallet",opts);
        }catch (error) {
            throw error;
        }
    }

    closeModal(reason?: WalletsModalCloseReason): void {
        this.singleWalletModal.close(reason);
    }

    showActionButton(): boolean {
        return isAppWallet(this.getConnectWallet()) && isMobile()
    }
}


