import { Component } from "solid-js";
import {
  FooterButton,
  FooterButtonCenter,
  FooterStyled,
  ImageStyled,
} from "./style";
import { ArrowIcon, H3, Text } from "../../../../components";
import { Translation } from "../../../../components/typography/Translation";
import { useTheme } from "solid-styled-components";
import imageUrl from "../../../../assets/ok_icon.png";
import { OKXLogo } from "../../../../components/icons/okxlogo-icon";
import { WalletInfo } from "@okxconnect/core";

interface ModalFooterProps {
  wallet: WalletInfo;
}

const ModalFooter: Component<ModalFooterProps> = (props) => {
  const theme = useTheme();

  return (
    <>
      <FooterStyled>
        <ImageStyled src={imageUrl} />
        <H3>{props.wallet.name}</H3>
        <FooterButton href={props.wallet.aboutUrl} blank>
          <Text color={theme.colors.text.contrast}>
            <Translation translationKey="common.get">GET</Translation>
          </Text>
          <ArrowIcon direction="right" />
        </FooterButton>
      </FooterStyled>

      <FooterButtonCenter>
        <Text color={theme.colors.disable}>
          <Translation translationKey="walletModal.mobileConnectionModal.PoweredBy">
            PoweredBy
          </Translation>
        </Text>
        <OKXLogo />
      </FooterButtonCenter>
    </>
  );
};

export default ModalFooter;
