export type Locales =
  | "en_US"
  | "ru_RU"
  | "zh_CN"
  | "zh"
  | "ar_AE"
  | "cs_CZ"
  | "de_DE"
  | "es_ES"
  | "es_LAT"
  | "fr_FR"
  | "id_ID"
  | "it_IT"
  | "nl_NL"
  | "pl_PL"
  | "pt_BR"
  | "pt_PT"
  | "ro_RO"
  | "tr_TR"
  | "uk_UA"
  | "vi_VN"
  | "zh_TW";
const validLocales: Locales[] = [
  "en_US",
  "ru_RU",
  "zh_CN",
  "zh",
  "ar_AE",
  "cs_CZ",
  "de_DE",
  "es_ES",
  "es_LAT",
  "fr_FR",
  "id_ID",
  "it_IT",
  "nl_NL",
  "pl_PL",
  "pt_BR",
  "pt_PT",
  "ro_RO",
  "tr_TR",
  "uk_UA",
  "vi_VN",
  "zh_TW"
];
export function isValidLocale(locale: string): locale is Locales {
  return validLocales.includes(locale as Locales);
}
