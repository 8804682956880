import type { Property } from 'csstype';
import { Component } from 'solid-js';
import { useTheme } from 'solid-styled-components';

interface RetryIconProps {
    fill?: Property.Color;
}

export const RetryIcon: Component<RetryIconProps> = _ => {
    const theme = useTheme();

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.56176 1.87482C3.8379 1.87482 4.06176 2.09867 4.06176 2.37482V3.69674C5.09916 2.7469 6.48174 2.16666 7.99996 2.16666C11.2216 2.16666 13.8333 4.77834 13.8333 8C13.8333 11.2217 11.2216 13.8333 7.99996 13.8333C4.7783 13.8333 2.16663 11.2217 2.16663 8C2.16663 7.72385 2.39048 7.5 2.66663 7.5C2.94277 7.5 3.16663 7.72385 3.16663 8C3.16663 10.6694 5.33058 12.8333 7.99996 12.8333C10.6693 12.8333 12.8333 10.6694 12.8333 8C12.8333 5.33062 10.6693 3.16666 7.99996 3.16666C6.68645 3.16666 5.49513 3.69037 4.62354 4.54148H6.22843C6.50457 4.54148 6.72843 4.76534 6.72843 5.04148C6.72843 5.31763 6.50457 5.54148 6.22843 5.54148H3.56176C3.28562 5.54148 3.06176 5.31763 3.06176 5.04148V2.37482C3.06176 2.09867 3.28562 1.87482 3.56176 1.87482Z" fill={theme!.colors.text.primary}/>
        </svg>
    );
};
