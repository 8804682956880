import type { Property } from 'csstype';
import { Component } from 'solid-js';
import { useTheme } from 'solid-styled-components';

interface CloseIconProps {
    fill?: Property.Color;
}

export const OKXLogo: Component<CloseIconProps> = props => {
    const theme = useTheme();
    const fill = (): string => props.fill || theme.colors.disable;

    return (


    <svg width="40" height="12" viewBox="0 0 40 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4356_43883)">
            <path d="M11.7336 0H0.269322C0.198282 0 0.130151 0.0282212 0.0799182 0.0784542C0.0296852 0.128687 0.00146484 0.196817 0.00146484 0.267857V11.7321C0.00146484 11.8032 0.0296852 11.8713 0.0799182 11.9215C0.130151 11.9718 0.198282 12 0.269322 12H11.7336C11.8046 12 11.8728 11.9718 11.923 11.9215C11.9732 11.8713 12.0015 11.8032 12.0015 11.7321V0.267857C12.0015 0.196817 11.9732 0.128687 11.923 0.0784542C11.8728 0.0282212 11.8046 0 11.7336 0ZM8.00129 7.73303C8.00129 7.80407 7.97307 7.87221 7.92283 7.92244C7.8726 7.97267 7.80447 8.00089 7.73343 8.00089H4.26736C4.19632 8.00089 4.12819 7.97267 4.07795 7.92244C4.02772 7.87221 3.9995 7.80407 3.9995 7.73303V4.26643C3.9995 4.19539 4.02772 4.12726 4.07795 4.07702C4.12819 4.02679 4.19632 3.99857 4.26736 3.99857H7.73343C7.80447 3.99857 7.8726 4.02679 7.92283 4.07702C7.97307 4.12726 8.00129 4.19539 8.00129 4.26643V7.73303Z" fill={fill()}/>
            <path d="M35.7391 4.00018H32.2725C32.2015 4.00018 32.1333 4.0284 32.0831 4.07864C32.0329 4.12887 32.0046 4.197 32.0046 4.26804V7.73465C32.0046 7.80569 32.0329 7.87382 32.0831 7.92405C32.1333 7.97428 32.2015 8.0025 32.2725 8.0025H35.7391C35.8101 8.0025 35.8783 7.97428 35.9285 7.92405C35.9787 7.87382 36.007 7.80569 36.007 7.73465V4.26697C36.0067 4.19611 35.9783 4.12826 35.9281 4.07826C35.8779 4.02825 35.81 4.00018 35.7391 4.00018Z" fill={fill()}/>
            <path d="M31.7379 0H28.2713C28.2002 0 28.1321 0.0282212 28.0819 0.0784542C28.0316 0.128687 28.0034 0.196817 28.0034 0.267857V3.73446C28.0034 3.8055 28.0316 3.87364 28.0819 3.92387C28.1321 3.9741 28.2002 4.00232 28.2713 4.00232H31.7379C31.8089 4.00232 31.8771 3.9741 31.9273 3.92387C31.9775 3.87364 32.0057 3.8055 32.0057 3.73446V0.267857C32.0057 0.196817 31.9775 0.128687 31.9273 0.0784542C31.8771 0.0282212 31.8089 0 31.7379 0Z" fill={fill()}/>
            <path d="M39.7376 0H36.271C36.2 0 36.1319 0.0282212 36.0816 0.0784542C36.0314 0.128687 36.0032 0.196817 36.0032 0.267857V3.73446C36.0032 3.8055 36.0314 3.87364 36.0816 3.92387C36.1319 3.9741 36.2 4.00232 36.271 4.00232H39.7376C39.8087 4.00232 39.8768 3.9741 39.927 3.92387C39.9773 3.87364 40.0055 3.8055 40.0055 3.73446V0.267857C40.0055 0.232682 39.9986 0.19785 39.9851 0.165352C39.9716 0.132854 39.9519 0.103327 39.927 0.0784542C39.9022 0.0535813 39.8726 0.0338512 39.8401 0.0203901C39.8076 0.00692902 39.7728 0 39.7376 0Z" fill={fill()}/>
            <path d="M31.7379 7.99982H28.2713C28.2002 7.99982 28.1321 8.02804 28.0819 8.07827C28.0316 8.1285 28.0034 8.19663 28.0034 8.26767V11.7321C28.0034 11.8032 28.0316 11.8713 28.0819 11.9215C28.1321 11.9718 28.2002 12 28.2713 12H31.7379C31.8089 12 31.8771 11.9718 31.9273 11.9215C31.9775 11.8713 32.0057 11.8032 32.0057 11.7321V8.2666C32.0055 8.19575 31.9771 8.12789 31.9269 8.07789C31.8767 8.02789 31.8087 7.99982 31.7379 7.99982Z" fill={fill()}/>
            <path d="M39.7376 7.99982H36.271C36.2 7.99982 36.1319 8.02804 36.0816 8.07827C36.0314 8.1285 36.0032 8.19663 36.0032 8.26767V11.7321C36.0032 11.8032 36.0314 11.8713 36.0816 11.9215C36.1319 11.9718 36.2 12 36.271 12H39.7376C39.8087 12 39.8768 11.9718 39.927 11.9215C39.9773 11.8713 40.0055 11.8032 40.0055 11.7321V8.2666C40.0052 8.19575 39.9769 8.12789 39.9267 8.07789C39.8765 8.02789 39.8085 7.99982 39.7376 7.99982Z" fill={fill()}/>
            <path d="M25.733 0H22.2664C22.1954 0 22.1272 0.0282212 22.077 0.0784542C22.0268 0.128687 21.9985 0.196817 21.9985 0.267857V3.73446C21.9985 3.8055 22.0268 3.87364 22.077 3.92387C22.1272 3.9741 22.1954 4.00232 22.2664 4.00232H25.733C25.804 4.00232 25.8722 3.9741 25.9224 3.92387C25.9726 3.87364 26.0009 3.8055 26.0009 3.73446V0.267857C26.0009 0.196817 25.9726 0.128687 25.9224 0.0784542C25.8722 0.0282212 25.804 0 25.733 0Z" fill={fill()}/>
            <path d="M25.733 7.99982H22.2664C22.1954 7.99982 22.1272 8.02804 22.077 8.07827C22.0268 8.1285 21.9985 8.19663 21.9985 8.26767V11.7321C21.9985 11.8032 22.0268 11.8713 22.077 11.9215C22.1272 11.9718 22.1954 12 22.2664 12H25.733C25.804 12 25.8722 11.9718 25.9224 11.9215C25.9726 11.8713 26.0009 11.8032 26.0009 11.7321V8.2666C26.0006 8.19575 25.9722 8.12789 25.922 8.07789C25.8718 8.02789 25.8039 7.99982 25.733 7.99982Z" fill={fill()}/>
            <path d="M21.9996 4.26375C21.9996 4.19271 21.9714 4.12458 21.9212 4.07435C21.8709 4.02411 21.8028 3.99589 21.7317 3.99589H17.9994V0.267857C17.9994 0.196817 17.9712 0.128687 17.921 0.0784542C17.8707 0.0282212 17.8026 0 17.7316 0H14.2687C14.1977 0 14.1295 0.0282212 14.0793 0.0784542C14.0291 0.128687 14.0009 0.196817 14.0009 0.267857V11.7273C14.0009 11.7984 14.0291 11.8665 14.0793 11.9167C14.1295 11.967 14.1977 11.9952 14.2687 11.9952H17.7353C17.8064 11.9952 17.8745 11.967 17.9247 11.9167C17.975 11.8665 18.0032 11.7984 18.0032 11.7273V7.99661H21.7366C21.8076 7.99661 21.8757 7.96839 21.926 7.91815C21.9762 7.86792 22.0044 7.79979 22.0044 7.72875L21.9996 4.26375Z" fill={fill()}/>
        </g>
        <defs>
            <clipPath id="clip0_4356_43883">
                <rect width="40.0039" height="12" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    );
};
