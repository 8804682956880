import { styled } from "solid-styled-components";

export const MobileConnectionModalStyled = styled.div``;

export const BodyStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 325px;
  gap: 24px;
`;
