
import {createEffect} from 'solid-js';
import { Action } from '../app/models/action-modal';
import { tonAction } from '../app/state/ton-modals-state';

/**
 * Manages the transaction modal window state.
 */
export class TransactionModalManager {

    private consumers: Array<(action: Action | null) => void> = [];

    constructor() {
        createEffect(() => {
            const currentAction = tonAction();
            this.consumers.forEach(consumer => consumer(currentAction));
        });
    }

    /**
     * Subscribe to the modal window state changes, returns unsubscribe function.
     */
    public onStateChange(consumer: (action: Action | null) => void): () => void {
        this.consumers.push(consumer);

        return () => {
            this.consumers = this.consumers.filter(c => c !== consumer);
        };
    }
}
