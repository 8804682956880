import { Component, createEffect, createSignal, Show } from 'solid-js';
import {
    ImageBackground,
    ImageStyled,
    imgSizeDefault,
    picSizeDefault,
    QrCodeBackground,
    QrCodeWrapper,
    qrNormalSize
} from './style';
import qrcode from 'qrcode-generator';
import { Transition } from 'solid-transition-group';
import { Styleable } from '../../models/styleable';
import { toPx } from '../../utils/css';
import { animate } from '../../utils/animate';

export interface QRCodeProps extends Styleable {
    sourceUrl: string;
    imageUrl?: string;
    disableCopy?: boolean;
}

export const QRCode: Component<QRCodeProps> = props => {
    let qrCodeCanvasRef: HTMLDivElement | undefined;
    let qrCodeWrapperRef: HTMLDivElement | undefined;
    let imageRef: HTMLDivElement | undefined;

    const [copyButtonOpened, setCopyButtonOpened] = createSignal(false);

    const [picSize, setPicSize] = createSignal(picSizeDefault);

    createEffect(() => {
        const errorCorrectionLevel = 'L';
        const cellSize = 4;
        const qr = qrcode(0, errorCorrectionLevel);
        qr.addData(props.sourceUrl);
        qr.make();
        qrCodeCanvasRef!.innerHTML = qr.createSvgTag(cellSize, 0);
        const qrSize = qrCodeCanvasRef!.firstElementChild!.clientWidth;

        const scale = Math.round((qrNormalSize / qrSize) * 100000) / 100000;

        if (imageRef) {
            const imgSize = Math.ceil(imgSizeDefault / (scale * cellSize)) * cellSize;
            const imgOffset = toPx(Math.ceil((qrSize - imgSize) / (2 * cellSize)) * cellSize);
            imageRef.style.top = imgOffset;
            imageRef.style.left = imgOffset;
            imageRef.style.height = toPx(imgSize);
            imageRef.style.width = toPx(imgSize);

            setPicSize(Math.round(picSizeDefault / scale));
        }

        qrCodeWrapperRef!.style.transform = `scale(${scale})`;
    });

    return (
        <QrCodeBackground class={props.class} >
            <QrCodeWrapper ref={qrCodeWrapperRef}>
                <div ref={qrCodeCanvasRef} />
                <Show when={props.imageUrl}>
                    <ImageBackground ref={imageRef}>
                        <ImageStyled src={props.imageUrl!} alt="" size={picSize()} />
                    </ImageBackground>
                </Show>
            </QrCodeWrapper>
            <Transition
                onBeforeEnter={el => {
                    animate(
                        el,
                        [
                            { opacity: 0, transform: 'translate(-50%, 44px)' },
                            { opacity: 1, transform: 'translate(-50%, 0)' }
                        ],
                        {
                            duration: 150,
                            easing: 'ease-out'
                        }
                    );
                }}
                onExit={(el, done) => {
                    animate(
                        el,
                        [
                            { opacity: 1, transform: 'translate(-50%, 0)' },
                            { opacity: 0, transform: 'translate(-50%, 44px)' }
                        ],
                        {
                            duration: 150,
                            easing: 'ease-out'
                        }
                    ).finished.then(() => {
                        done();
                    });
                }}
            >
            </Transition>
        </QrCodeBackground>
    );
};
