
export enum OKX_UI_CONNECTION_AND_TRANSACTION_EVENT {
    OKX_UI_CONNECTION_STARTED = 'okx-connect-ui-connection-started',
    OKX_UI_CONNECTION_COMPLETED = 'okx-connect-ui-connection-completed',
    OKX_UI_CONNECTION_ERROR = 'okx-connect-ui-connection-error',
    OKX_UI_CONNECTION_RESTORING_STARTED = 'okx-connect-ui-connection-restoring-started',
    OKX_UI_CONNECTION_RESTORING_COMPLETED = 'okx-connect-ui-connection-restoring-completed',
    OKX_UI_CONNECTION_RESTORING_ERROR = 'okx-connect-ui-connection-restoring-error',
    OKX_UI_DISCONNECTION = 'okx-connect-ui-disconnection',
    OKX_UI_TRANSACTION_SENT_FOR_SIGNATURE = 'okx-connect-ui-transaction-sent-for-signature',
    OKX_UI_TRANSACTION_SIGNED = 'okx-connect-ui-transaction-signed',
    OKX_UI_TRANSACTION_SIGNING_FAILED = 'okx-connect-ui-transaction-signing-failed',
}
