import { Component, Show } from "solid-js";
import { H1StyledMaxWidth, LoaderContainerStyled } from "./style";
import { OKXLoaderIconWithLogo } from "../../../../components/icons/okx-loader-icon";

interface LoadingModalPageProps {
  loadingTextTranslationKey?: string;
  loadingText?: string;
}

const LoadingModalPage: Component<LoadingModalPageProps> = (props) => {
  return (
    <LoaderContainerStyled>
      <OKXLoaderIconWithLogo />
      <Show
        when={
          props.loadingTextTranslationKey || props.loadingTextTranslationKey
        }
      >
        <H1StyledMaxWidth translationKey={props.loadingTextTranslationKey}>
          {props.loadingText}
        </H1StyledMaxWidth>
      </Show>
    </LoaderContainerStyled>
  );
};

export default LoadingModalPage;
