import { styled } from "solid-styled-components";
import {
  Button,
  ErrorIcon,
  H1,
  Image,
  Link,
  Text,
} from "../../../../components";
import { media } from "../../../../styles/media";
import { rgba } from "../../../../utils/css";

export const H1Styled = styled(H1)`
  padding: 12px 64px;
`;

export const H1StyledMaxWidth = styled(H1)`
  max-width: 262px;
`;

export const H1StyledRetry = styled(H1StyledMaxWidth)`
  font-size: 14px;
  color: ${(props) => props.theme!.colors.text.primary};
`;

export const RetryButtonStyle = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme!.colors.text.primary};
  padding: 0px;
`;

export const LoaderContainerStyled = styled.div`
  margin: 30px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${media("mobile")} {
    height: 160px;
    align-items: center;
  }
`;

export const ErrorIconStyled = styled(ErrorIcon)`
  margin-bottom: 16px;
`;

export const ButtonsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const ButtonsQRContainerStyled = styled.div`
  background-color: ${(props) => props.theme!.colors.text.primary};
  height: 48px;
  width: 262px;
  justify-content: center;
  border-radius: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TextStyled = styled(Text)`
  max-width: 262px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
  color: ${(props) => props.theme!.colors.text.contrast};
`;

export const ContainedButton = styled(Button)`
  height: 48px;
  width: 262px;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
`;

export const ContainedPrimaryButton = styled(ContainedButton)`
  background-color: ${(props) => props.theme!.colors.text.primary};
`;

export const ContainedSecondaryButton = styled(ContainedButton)`
  background-color: transparent;
  border-color: ${(props) => props.theme!.colors.border.secondary};
  border-width: 0.5px;
  border-style: solid;
`;

export const ContainedPrimaryButtonText = styled(H1StyledMaxWidth)`
  font-size: 14px;
  color: ${(props) => props.theme!.colors.background.primary};
`;

export const ContainedSecondaryButtonText = styled(H1StyledMaxWidth)`
  font-size: 14px;
  color: ${(props) => props.theme!.colors.text.primary};
`;

export const FooterStyled = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  border-top: 0.5px solid
    ${(props) => rgba(props.theme!.colors.icon.secondary, 0.2)};
  margin-top: 32px;
`;

export const ImageStyled = styled(Image)`
  width: 32px;
  height: 32px;
  border-radius: 16px;
`;

export const FooterButton = styled(Link)`
  margin-left: auto;
  display: flex;
`;

export const FooterButtonCenter = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;
