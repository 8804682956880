import {
  WalletInfo,
  EngineConnectInfo,
  encodeConnectParams,
  encodeTWAConnectURLParams,
  getAppWalletDeepLink,
  getUniversalLink, getTelegramWalletTWAUrl, openOKXTMAWalletlinkWithFallback, openOKXDeeplinkWithFallback, logDebug
} from "@okxconnect/core";
import {Component, createEffect, createMemo, createSignal, JSX, onCleanup, onMount, Show, useContext,} from "solid-js";
import {BodyStyled, MobileConnectionModalStyled, StyledIconButton,} from "./style";
import {useTheme} from "solid-styled-components";
import {MobileConnectionQR} from "./mobile-connection-qr";
import {ConnectorContext} from "../../../../../config/connector.context";
import ConnectAppTgModalPage from "../layout/ConnectAppTgModalPage";
import LoadingModalPage from "../layout/LoadingModalPage";
import ModalFooter from "../layout/ModalFooter";
import { H1Styled } from "../style";
import ConnectionDeclinedModalPage from "../layout/ConnectionDeclinedModalPage";
import ContinueInWalletModalPage from "../layout/ContinueInWalletModalPage";
import {OKXConnectUiContext} from "../../../../../config/okx-connect-ui.context";
import { WalletInfoRemoteWithOpenMethod, WalletInfoWithOpenMethod, WalletOpenMethod } from "../../../../../models";

export interface MobileConnectionProps {
  wallet: WalletInfo;
  onBackClick: () => void;
  backDisabled?: boolean;
  connect: () => Promise<unknown>;
  isSingleWallet: boolean;
  universalLinkParams: () => EngineConnectInfo | null;
  lastSelectedWalletInfo: WalletInfoRemoteWithOpenMethod | {
    openMethod: WalletOpenMethod;
  } | null;
  setLastSelectedWalletInfo(walletInfo: WalletInfoWithOpenMethod | {
    openMethod: WalletOpenMethod;
} | null): void;
}

export const MobileConnectionModal: Component<MobileConnectionProps> = (
  props
) => {
  const theme = useTheme();
  const [firstClick, setFirstClick] = createSignal(true);
  const [showQR, setShowQR] = createSignal(false);
  const [connectClicked, setConnectClicked] = createSignal(false);
  const showAppAndTgState = createMemo(
    () => !props.isSingleWallet && !connectClicked()
  );
  const [connectionErrored, setConnectionErrored] = createSignal(false);
  const connector = useContext(ConnectorContext);
  const [appDeepLink, setAppDeepLink] = createSignal<string | null>(null);
  const [miniUniversalLink, setMiniUniversalLink] = createSignal<string | null>(
    null
  );
  const [qrLink, setQrLink] = createSignal<string | null>(null);
  const okxConnectUI = useContext(OKXConnectUiContext)

  const unsubscribe = connector?.onStatusChange(
    () => {},
    () => {
      setConnectionErrored(true);
      const info = props.lastSelectedWalletInfo;
      if (info && info.openMethod && info.openMethod === "qrcode") {
        if (showQR()) {
          onCloseQR();
        }
      }
    }
  );
  createEffect(() => {
    var info = props.universalLinkParams();
    if (info) {
      props.setLastSelectedWalletInfo({
        ...props.wallet,
        openMethod: "universal-link",
      });

      logDebug(`createEffect  ${JSON.stringify(info)}`)
      var connectRequest = info.connectRequest
      var tonConnectRequest = info.tonConnectRequest
      if (!tonConnectRequest){
        tonConnectRequest = connectRequest
      }

      if (connectRequest) {
        var encodeParams = encodeConnectParams({
          ...tonConnectRequest,
          redirect: "none"
        })
        var qrLink = getUniversalLink(getAppWalletDeepLink(encodeParams))
        setQrLink(qrLink);

        var appConnectEncodeParams = encodeConnectParams({
          ...tonConnectRequest,
          redirect: info.redirect ?? "none"
        })
        var appDeepLink = getAppWalletDeepLink(appConnectEncodeParams)
        setAppDeepLink(appDeepLink)


        var encodeTGParams = encodeTWAConnectURLParams({
          ...connectRequest,
          redirect: "none"
        })

        var tgUrl = getTelegramWalletTWAUrl(encodeTGParams, info.tmaReturnUrl ?? "back")
        setMiniUniversalLink(tgUrl)
        logDebug(`setUniversalLink  ${appDeepLink}   tgUrl  ${tgUrl}    qrLink ${qrLink}`)
      }
    }
  });

  onMount(async () => {
    try {
      await props.connect().catch((error) => 
        logDebug(`MobileConnectionModal onMount error ${JSON.stringify(error)}`)
      )
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  });

  const onRetry = (): void => {
    setConnectionErrored(false);
    setConnectClicked(false);
    const forceRedirect = !firstClick();
    setFirstClick(false);

    try {
      props.connect();
    } catch (error) {
      console.error("Error while connecting:", error);
    }
  };

  const onOpenQR = (): void => {
    setConnectionErrored(false);
    setShowQR(true);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "qrcode",
    });
  };

  const onCloseQR = (): void => {
    setShowQR(false);
    props.setLastSelectedWalletInfo({
      ...props.wallet,
      openMethod: "universal-link",
    });
  };

  const onBack = (): void => {
    if (showQR()) {
      onCloseQR();
    } else {
      props.onBackClick();
    }
  };

  const onConnectApp = (): void => {
    setConnectClicked(true);
    openOKXDeeplinkWithFallback(appDeepLink()!)
  };

  const onConnectMini = (): void => {
    // setConnectClicked(true);
    okxConnectUI?.closeModal()
    openOKXTMAWalletlinkWithFallback(miniUniversalLink()!)
  };

  if (unsubscribe) {
    onCleanup(unsubscribe);
  }

  // {/*<MobileConnectionQR universalLink={universalLink()} walletInfo={props.wallet} />*/}

  return (
    <MobileConnectionModalStyled data-tc-wallets-modal-connection-mobile="true">
      <Show<boolean, () => JSX.Element> when={!props.backDisabled || showQR()}>
        <StyledIconButton icon="arrow" onClick={onBack} />
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() === null}>
        <H1Styled>OKX Connect</H1Styled>
        <LoadingModalPage />
        <ModalFooter wallet={props.wallet} />
      </Show>

      <Show<boolean, () => JSX.Element> when={props.universalLinkParams() !== null}>
        <Show<boolean, () => JSX.Element> when={showQR()}>
            <MobileConnectionQR
                universalLink={qrLink()}
                walletInfo={props.wallet}
            />
        </Show>

        <Show<boolean, () => JSX.Element> when={!showQR()}>
          <H1Styled>OKX Connect</H1Styled>
          <BodyStyled>
            <Show<boolean, () => JSX.Element> when={connectionErrored()}>
              <ConnectionDeclinedModalPage onRetry={onRetry} />
            </Show>

            <Show<boolean, () => JSX.Element> when={!connectionErrored()}>
              <Show<boolean, () => JSX.Element> when={showAppAndTgState()}>
                <ConnectAppTgModalPage
                  onConnectApp={onConnectApp}
                  onConnectMini={onConnectMini}
                />
              </Show>

              <Show<boolean, () => JSX.Element> when={!showAppAndTgState()}>
                <ContinueInWalletModalPage
                  onOpenQR={onOpenQR}
                  onRetry={onRetry}
                />
              </Show>
            </Show>
          </BodyStyled>

          <ModalFooter wallet={props.wallet} />
        </Show>
      </Show>
    </MobileConnectionModalStyled>
  );
};
